import { inject, Injectable } from '@angular/core';
import { LoadConnectedCompanies } from '@clover/product-v2/state/connected-companies/connected-companies.actions';
import { ICompany } from '@clover/product-v2/state/connected-companies/connected-companies.entities';
import { ProductsService } from '@clover/product-v2/state/products.service';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';

export interface ConnectedCompaniesStateModel {
  companies: ICompany[];
}

@State<ConnectedCompaniesStateModel>({
  name: 'connectedCompanies',
  defaults: {
    companies: [],
  },
})
@Injectable()
export class ConnectedCompaniesState {
  private readonly productsService = inject(ProductsService);
  @Action(LoadConnectedCompanies)
  loadConnectedCompanies(ctx: StateContext<ConnectedCompaniesStateModel>) {
    return this.productsService.searchCompanies().pipe(
      tap((response) => {
        ctx.patchState({
          companies: response.data,
        });
      }),
    );
  }
}
